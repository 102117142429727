import React, { Component } from "react";
import { Row, Col, Form, Spinner, Button } from "react-bootstrap"
import config from "../config";
import axios from 'axios';
import auth0Client from "../Auth/Auth"

import { userNotification } from "../components/UserNotification";
import Webhook from "../components/Settings/Webhook";
import { Helmet } from "react-helmet";

export default class Settings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      user: [],
      firstName: "",
      lastName: "",
      phoneNumber: "",
      apiButtonLoading: false,
      secretButtonLoading: false,
      editLocked: true,
      editSecretLocked: true,
      newApiKey: '',
      apiKey: '',
      secret: '',
      priceResetLoading: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleApiKeySubmit = this.handleApiKeySubmit.bind(this);
    this.handlePriceResetToggle = this.handlePriceResetToggle.bind(this);
    //this.handleSecretSubmit = this.handleSecretSubmit.bind(this);
  }

  async componentDidMount() {
    window.analytics.page(`Settings`);
    this.setState({ isLoading: true });
    try {
      await this.getUser();

    } catch (e) {
      alert(e + " Error Loading User");
    }
    this.setState({ isLoading: false });

  }

  handleChange(event) {
    this.setState({ [`${event.target.id}`]: event.target.value });
  }

  async handleApiKeySubmit(event) {

    event.preventDefault();
    this.setState({ apiButtonLoading: true })
    await this.updateApiKey()
    this.setState({ apiButtonLoading: false })

  }

  async updateApiKey() {
    try {
      const response = await axios.patch(`${config.api.BASE_URL}/seller_accounts/update_api_key`, {
        apiKey: this.state.newApiKey,
      }, {
        headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.state.user.currentAccountId }
      });

      this.setState({ apiKey: response.data.apiKey, editLocked: true, newApiKey: '' });
      userNotification('API Key successfully updated', 'success');

    } catch (error) {
      userNotification('Error updating API Key', 'danger');

    }
  }

  async handleSecretSubmit(event) {
    // console.log(event)
    event.preventDefault();
    this.setState({ secretButtonLoading: true })
    await this.updateSecret()
    this.setState({ secretButtonLoading: false })

  }

  async getUser() {
    let response = await this.user()
    this.setState({ user: response.data });
    this.setState({ apiKey: response.data.sellerAccount.apiKey })
  }

  async user() {
    return axios.get(`${config.api.BASE_URL}/users`, {
      headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}` }
    });
  }

  // New method to update the Price Reset toggle state
  async handlePriceResetToggle(event) {
    const newPriceReset = event.target.checked; // get new value from toggle
    this.setState({ priceResetLoading: true });
    try {
      const response = await axios.patch(
        `${config.api.BASE_URL}/seller_accounts/update_price_reset`,
        { priceReset: newPriceReset },
        {
          headers: {
            'Authorization': `Bearer ${auth0Client.getAccessToken()}`,
            'shopdeploy-acc': this.state.user.currentAccountId
          }
        }
      );
      // Update the sellerAccount.priceReset in the user state
      let updatedUser = { ...this.state.user };
      updatedUser.sellerAccount.priceReset = response.data.priceReset !== undefined ? response.data.priceReset : newPriceReset;
      this.setState({ user: updatedUser });
      userNotification('Price Reset successfully updated', 'success');
    } catch (error) {
      userNotification('Error updating Price Reset', 'danger');
    }
    this.setState({ priceResetLoading: false });
  }

  renderSettings() {
    // console.log(this.state.user)
    return (<>
      <div className="mb-3"><h2>{this.state.user.sellerAccount.name} Settings</h2></div>
      <Row className="justify-content-md-center mb-5">
        <Col xs lg="3">
          <strong>Takealot API Key</strong>
        </Col>
        <Col md="5">
          <Form onSubmit={this.handleApiKeySubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} >
                <Form.Control type="text" value={this.state.editLocked ? this.state.apiKey : this.state.newApiKey} placeholder='Paste in Takealot API Key' onChange={this.handleChange} id="newApiKey" disabled={this.state.editLocked} />
              </Form.Group>

            </Row>

            <Button className="me-2" style={{ 'width': '120px' }} variant="outline-secondary" onClick={() => { this.setState(prevState => ({ editLocked: !prevState.editLocked })); }}>
              {this.state.editLocked ? 'Edit Api Key' : 'Cancel'}
            </Button>

            {!this.state.editLocked &&
              <Button className="ml-2" variant="primary" type='submit' style={{ 'minWidth': '61px' }} disabled={!this.state.newApiKey}>
                {this.state.apiButtonLoading ? <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> : "Save"}
              </Button>}
          </Form>
        </Col>
      </Row>

      {/* Render the Price Reset toggle if available */}
      {this.state.user.sellerAccount.priceResetAvailable && (
        <Row className="justify-content-md-center mb-5">
          <Col xs lg="3">
            <strong>Price Reset</strong>
            <div><small className="text-muted">Automatically set your price to the Max Price when your min price is reached.</small></div>

          </Col>
          <Col md="5">
            <Form>
              <Form.Check
                type="switch"
                id="priceResetSwitch"
                label={this.state.user.sellerAccount.priceReset ? "Enabled" : "Disabled"}
                checked={this.state.user.sellerAccount.priceReset}
                onChange={this.handlePriceResetToggle}
                disabled={this.state.priceResetLoading}
              />
            </Form>
          </Col>
        </Row>
      )}

      {this.state.user.sellerAccount.webhook && <Webhook webhook={this.state.user.sellerAccount.webhook} currentAccountId={this.state.user.currentAccountId} />
      }

    </>
    )
  }


  render() {
    return (
      <div>
        <Helmet>
          <title>Settings</title>
        </Helmet>
        {!this.state.isLoading ? this.renderSettings() : <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>}
      </div>
    )
  }
}

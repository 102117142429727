import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import config from "../../config";
import auth0Client from "../../Auth/Auth";
import { Table, Spinner, Form, Badge, Pagination } from 'react-bootstrap';
import { BsCreditCard2Front, BsBank } from 'react-icons/bs';

const StatusBadge = ({ status }) => {
    const getBadgeColor = (status) => {
        switch (status) {
            case 'sent':
                return 'info';
            case 'draft':
                return 'secondary';
            case 'overdue':
                return 'danger';
            case 'paid':
                return 'success';
            case 'partially_paid':
                return 'warning';
            case 'void':
                return 'dark';
            case 'unpaid':
                return 'danger';
            default:
                return 'primary';
        }
    };

    const formatStatus = (status) => {
        return status.split('_').map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    };

    return (
        <Badge bg={getBadgeColor(status)}>
            {formatStatus(status)}
        </Badge>
    );
};

const PaymentMethodBadge = ({ method }) => {
    const getMethodDetails = (method) => {
        switch (method.toLowerCase()) {
            case 'paystack':
                return {
                    icon: <BsCreditCard2Front className="me-1" />,
                    label: 'Card',
                    color: 'success'
                };
            case 'eft':
                return {
                    icon: <BsBank className="me-1" />,
                    label: 'EFT',
                    color: 'info'
                };
            default:
                return {
                    label: method,
                    color: 'secondary'
                };
        }
    };

    const details = getMethodDetails(method);

    return (
        <Badge bg={details.color} className="d-flex align-items-center" style={{ width: 'fit-content' }}>
            {details.icon} {details.label}
        </Badge>
    );
};

const YesNoBadge = ({ value }) => {
    return (
        <Badge bg={value ? 'success' : 'danger'}>
            {value ? 'Yes' : 'No'}
        </Badge>
    );
};

const Invoices = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState(['All']);
    const [pagination, setPagination] = useState({
        per_page: 100,
        page_number: 1,
        total_pages: 1,
        total_count: 0
    });

    const statusOptions = [
        'All',
        'sent',
        'draft',
        'overdue',
        'paid',
        'partially_paid',
        'void',
        'unpaid'
    ];

    useEffect(() => {
        getInvoices()
    }, [selectedStatuses, pagination.page_number])

    const getInvoices = () => {
        let queryParams = [];

        if (!selectedStatuses.includes('All')) {
            const statusParams = selectedStatuses
                .map(status => `status[]=${status.toLowerCase()}`);
            queryParams.push(...statusParams);
        }

        queryParams.push(`page=${pagination.page_number}`);
        queryParams.push(`per_page=${pagination.per_page}`);

        const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

        axios.get(`${config.api.BASE_URL}/admin/invoices${queryString}`, {
            headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}` }
        })
            .then(res => {
                setInvoices(res.data.invoices);
                setPagination(res.data.pagination);
                setIsLoading(false)
            })
    }

    const handleStatusChange = (status) => {
        setSelectedStatuses(prev => {
            if (status === 'All') {
                return ['All'];
            } else {
                let newStatuses;
                if (prev.includes(status)) {
                    newStatuses = prev.filter(s => s !== status);
                } else {
                    newStatuses = [...prev.filter(s => s !== 'All'), status];
                }
                return newStatuses.length === 0 ? ['All'] : newStatuses;
            }
        });
    };

    const handlePageChange = (pageNumber) => {
        setPagination(prev => ({
            ...prev,
            page_number: pageNumber
        }));
    };

    const PaginationComponent = () => {
        let items = [];

        items.push(
            <Pagination.Item
                key={1}
                active={1 === pagination.page_number}
                onClick={() => handlePageChange(1)}
            >
                1
            </Pagination.Item>
        );

        if (pagination.page_number > 3) {
            items.push(<Pagination.Ellipsis key="ellipsis1" />);
        }

        for (let number = Math.max(2, pagination.page_number - 1);
            number <= Math.min(pagination.total_pages - 1, pagination.page_number + 1);
            number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === pagination.page_number}
                    onClick={() => handlePageChange(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }

        if (pagination.page_number < pagination.total_pages - 2) {
            items.push(<Pagination.Ellipsis key="ellipsis2" />);
        }

        if (pagination.total_pages > 1) {
            items.push(
                <Pagination.Item
                    key={pagination.total_pages}
                    active={pagination.total_pages === pagination.page_number}
                    onClick={() => handlePageChange(pagination.total_pages)}
                >
                    {pagination.total_pages}
                </Pagination.Item>
            );
        }

        return (
            <Pagination>
                <Pagination.Prev
                    onClick={() => handlePageChange(pagination.page_number - 1)}
                    disabled={pagination.page_number === 1}
                />
                {items}
                <Pagination.Next
                    onClick={() => handlePageChange(pagination.page_number + 1)}
                    disabled={pagination.page_number === pagination.total_pages}
                />
            </Pagination>
        );
    };

    const Charge = (invoiceId) => {
        axios.post(`${config.api.BASE_URL}/admin/invoices/${invoiceId}/payment`, {},
            { headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}` } })
            .then(res => { console.log(res) },
                err => { console.log(err) })

    }

    const ChargeButton = (invoice) => {
        return (
            <>
                {invoice.hasValidPaymentMethod && invoice.balance > 0 && invoice.status !== 'void' && <button className="btn btn-primary btn-sm" onClick={() => Charge(invoice.id)}>Charge ({invoice.balance})</button>}
            </>
        )
    }

    const TableRows = () => {
        return (
            invoices.map((invoice, index) => {
                return (
                    <tr
                        key={index}
                        className={invoice.suspended ? 'table-danger' : ''}
                    >
                        <td>{invoice.dueDate}</td>
                        <td>
                            {invoice.zohoInvoiceId ? (
                                <a href={`https://billing.zoho.com/app/775763789#/invoices/${invoice.zohoInvoiceId}`} target="_blank" rel="noopener noreferrer">
                                    {invoice.invoiceNumber}
                                </a>
                            ) : invoice.invoiceNumber}
                        </td>
                        <td>
                            {invoice.zohoSubscriptionId ? (
                                <a href={`https://billing.zoho.com/app/775763789#/subscriptions/${invoice.zohoSubscriptionId}`} target="_blank" rel="noopener noreferrer">
                                    {invoice.companyName}
                                </a>
                            ) : invoice.companyName}
                        </td>
                        <td>{invoice.billingEmail}</td>
                        <td><StatusBadge status={invoice.status} /></td>
                        <td>{invoice.total}</td>
                        <td>{invoice.balance}</td>
                        <td><PaymentMethodBadge method={invoice.paymentMethod} /></td>
                        <td>{invoice.paymentCount}</td>
                        <td><ChargeButton {...invoice} /></td>
                        <td>
                            {invoice.xeroInvoiceId ? (
                                <a href={`https://go.xero.com/app/!Rt940/invoicing/view/${invoice.xeroInvoiceId}`} target="_blank" rel="noopener noreferrer">
                                    <YesNoBadge value={true} />
                                </a>
                            ) : <YesNoBadge value={false} />}
                        </td>
                        <td>
                            {invoice.contactId && (
                                <a href={`https://go.xero.com/app/!Rt940/contacts/contact/${invoice.contactId}`} target="_blank" rel="noopener noreferrer">
                                    View Contact
                                </a>
                            )}
                        </td>
                        <td><YesNoBadge value={invoice.inXero} /></td>
                    </tr>
                )
            })
        )
    }

    return (
        <div>
            <Form className="mb-3">
                <Form.Group>
                    <Form.Label>Filter by Status:</Form.Label>
                    <div className="d-flex flex-wrap gap-3">
                        {statusOptions.map((status) => (
                            <Form.Check
                                key={status}
                                type="checkbox"
                                id={`status-${status}`}
                                label={status}
                                checked={selectedStatuses.includes(status)}
                                onChange={() => handleStatusChange(status)}
                            />
                        ))}
                    </div>
                </Form.Group>
            </Form>

            {!isLoading && (
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Due Date</th>
                                <th>Invoice</th>
                                <th>Organisation</th>
                                <th>Email</th>
                                <th>Status</th>
                                <th>Total</th>
                                <th>Balance</th>
                                <th>Method</th>
                                <th>Attempts</th>
                                <th></th>
                                <th>Xero</th>
                                <th>Contact</th>
                                <th>In Xero (old)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRows />
                        </tbody>
                    </Table>

                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            Showing {((pagination.page_number - 1) * pagination.per_page) + 1} to {Math.min(pagination.page_number * pagination.per_page, pagination.total_count)} of {pagination.total_count} entries
                        </div>
                        <PaginationComponent />
                    </div>
                </>
            )}
        </div>
    );
};

export default Invoices;